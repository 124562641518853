import PropTypes from 'prop-types';
import Link from '@/components/atoms/Link';
import Text from '@/components/atoms/Text';
import Image from '@/components/atoms/Image';
import style from './index.module.scss';
import imageDummy from '@/public/dummy.png';
import Icon from '@/components/atoms/Icon';
import variables from '@/styles/variables';
import BrandImage from '@/components/atoms/BrandImage';
import getPrimaryColor from '@/utils/getPrimaryColor';
import useDeviceType from '@/hooks/useDeviceType';

const BrandCard = (props) => {
  const { image, href, text = '', store = null, plusStore = null } = props;
  const { isDesktop } = useDeviceType();

  return (
    <div
      className={style.card}
      style={{
        borderColor: store ? getPrimaryColor(store) : getPrimaryColor()
      }}
    >
      <Link href={href}>
        <div className={style.layout}>
          <div className={style.img}>
            <div className={style.flow}>
              <Icon
                type="double-chevron"
                color={store ? getPrimaryColor(store) : getPrimaryColor()}
              />
            </div>
            <Image
              alt={store}
              br="medium"
              src={image || imageDummy.src}
              imageStyles={{
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          {store && (
            <div
              style={{
                position: 'relative',
                width: '100px',
                alignSelf: 'start',
                marginBlock: '1rem'
              }}
            >
              <BrandImage
                height={isDesktop ? 12 : 9}
                brand={store}
              />
            </div>
          )}
          {plusStore && (
            <div
              style={{
                position: 'relative',
                width: '100px',
                alignSelf: 'start',
                marginBlock: '1.5rem'
              }}
            >
              <Text
                textCase="uppercase"
                weight="bolder"
                textSize={isDesktop ? 'l' : 'xxs'}
                style={{
                  maxWidth: 'fit-content',
                  marginRight: 'auto'
                }}
                textColor="white"
                textStyle={{ fontFamily: 'Druk Trial Super' }}
              >
                {plusStore}
              </Text>
            </div>
          )}
          {text && (
            <Text
              textCase="none"
              weight="light"
              textSize="xs"
              style={{ maxWidth: 'fit-content', marginRight: 'auto' }}
              textStyle={
                isDesktop ? { alignSelf: 'flex-start', width: '80%' } : {}
              }
              textColor={variables['color-white']}
            >
              {text}
            </Text>
          )}
        </div>
      </Link>
    </div>
  );
};

BrandCard.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.string.isRequired,
  store: PropTypes.string,
  plusStore: PropTypes.string
};

export default BrandCard;
