import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Image from '../Image';
import styles from './index.module.scss';

const BrandImage = ({
  primary = false,
  brand = 'vaypol',
  isDark = false,
  height = 25,
  width = 'auto',
  event = false,
  maxWidth = 'none',
  imageWidth = 'auto',
  imageHeight = '100'
}) => {
  const storeId = useSelector((state) => state.store);

  return (
    <div
      key={brand || storeId}
      className={styles.img}
      style={{ height: `${height}px`, width: width || 'auto' }}
    >
      <Image
        alt={`${primary ? storeId : brand} logo`}
        src={`/brandlogos/${primary ? storeId : brand}${
          isDark ? '-black' : ''
        }${!isDark && event ? '-event' : ''}.webp`}
        imageStyles={{ maxWidth, objectFit: 'contain' }}
        width={imageWidth || 'auto'}
        height={imageHeight}
      />
    </div>
  );
};

BrandImage.propTypes = {
  brand: PropTypes.string,
  isDark: PropTypes.bool,
  primary: PropTypes.bool,
  height: PropTypes.number,
  maxWidth: PropTypes.string,
  event: PropTypes.bool,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  width: PropTypes.string
};

export default BrandImage;
